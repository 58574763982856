label {
  color: #9e9e9e;
  font-size: .8rem;
}
input:not([type]), input[type=date]:not(.browser-default), input[type=datetime-local]:not(.browser-default), input[type=datetime]:not(.browser-default), input[type=email]:not(.browser-default), input[type=number]:not(.browser-default), input[type=password]:not(.browser-default), input[type=search]:not(.browser-default), input[type=tel]:not(.browser-default), input[type=text]:not(.browser-default), input[type=time]:not(.browser-default), input[type=url]:not(.browser-default), textarea.materialize-textarea {
  background-color: initial;
  border: none;
  border-bottom: 1px solid #9e9e9e;
  border-radius: 0;
  box-shadow: none;
  box-sizing: initial;
  font-size: 1rem;
  height: 3rem;
  margin: 0 0 5px;
  outline: none;
  padding: 0;
  transition: all .3s;
  width: 100%;
}
.ps-form-header.MuiTypography-root {
  color: $primary;
  margin-top: 15px;
  margin-bottom: 15px;
}
.btn {
  background-color: $primary;
  margin: 5px;
}
.center-div {
  text-align: center;
}
.center-div * {
  display: inline-block;
}
.MuiButton-root.right {
  float: right;
}
.expandable-form-div {
  width: 99%;
}
.ps-form-checkbox input[type='checkbox']:not(:checked),
.ps-form-checkbox input[type='checkbox']:checked {
  position: relative;
  opacity: 1;
  pointer-events: auto;
  margin-left: 5px;
  vertical-align: middle;
}

.MuiButton-root[title~='Remove'] {
  background-color: $ps-red;
  color: white;
  padding: 6px;
  min-width: 10px;
}
.MuiButton-root[title~='Remove']:hover {
  background-color: lighten($ps-red, 7%);
}

.color-picker-container {
  padding-left: 5px;
  font-size: 1.5em;
  vertical-align: middle;
}

.color-picker-button {
  background: none;
  border: none;
}

.color-picker-color {
  width: 15pt;
  height: 15px;
  border:solid 1px #000;
}

.color-picker-picker {
  position: absolute;
  margin: 5pt 0;
}

textarea {
  background-color: initial;
  height: 3rem;
  width: 100%;
}
textarea {
  overflow: auto;
}
